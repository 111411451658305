/** @format */

export const TLD = {
  agency: 'for digital agencies',
  app: 'for mobile apps',
  art: 'for artists',
  asia: 'Companies and individuals based in the Asia-Pacific region',
  band: 'for bands',
  biz: 'Intended for business use',
  blog: 'for blogs',
  casino: 'for online casinos',
  chat: 'for chat applications',
  city: 'for cities',
  club: 'for clubs',
  co: 'for companies',
  com: 'Considered commercial and is the most widely used TLD on the Internet',
  coop: 'Reserved for cooperative businesses',
  design: 'for designers',
  dev: 'for developers',
  digital: 'for digital businesses',
  domains: 'for domain name registries',
  ecom: 'for e-commerce websites',
  edu: 'Available to higher learning educational institutions that grant degrees',
  education: 'for educational institutions',
  events: 'for event planners',
  fashion: 'for fashion brands',
  finance: 'for financial institutions',
  food: 'for food and beverage businesses',
  games: 'for game developers',
  global: 'for global businesses',
  help: 'for customer support websites',
  home: 'for personal websites',
  hotel: 'for hotels',
  inc: 'for companies',
  info: 'Used for informational purposes',
  io: 'for technology companies',
  jobs: 'Used for employment and job-related websites',
  life: 'for personal websites',
  live: 'for live streaming websites',
  media: 'for media companies',
  mobi: 'Dedicated to sites that are made for mobile device use',
  mobile: 'for mobile apps',
  name: 'For individual use generally using a first and last name',
  net: 'Usually used by Network Providers, but used widely by businesses and individuals for other purposes',
  online: 'for online businesses',
  org: 'Commonly used by non profit organizations',
  pro: 'Used for specific professions, usually intended for licensed professions. Examples include: Attorneys, Doctors etc.',
  realestate: 'for real estate businesses',
  rent: 'for rental properties',
  review: 'for review websites',
  rock: 'for rock bands',
  school: 'for schools',
  shop: 'for online stores',
  site: 'for general use',
  space: 'for space-related businesses',
  tech: 'for technology companies',
  tel: 'Internet Communication Services',
  travel: 'Reserved for the travel and tourist industry and must be verified as a legitimate travel-related entity',
  tv: 'for television networks',
  us: 'for businesses in the United States',
  video: 'for video production companies',
  vip: 'for high-end businesses',
  web: 'for websites',
  world: 'for global businesses',

  ac: 'Ascension Island',
  ad: 'Andorra',
  ae: 'United Arab Emirates',
  af: 'Afghanistan',
  ag: 'Antigua and Barbuda',
  ai: 'Anguilla',
  am: 'Armenia',
  an: 'Antigua and Barbuda',
  ao: 'Angola',
  aq: 'Antarctica',
  ar: 'Argentina',
  as: 'American Samoa',
  at: 'Austria',
  au: 'Australia',
  aw: 'Aruba',
  az: 'Azerbaijan',
  ba: 'Bosnia and Herzegovina',
  bb: 'Barbados',
  bd: 'Bangladesh',
  be: 'Belgium',
  bf: 'Burkina Faso',
  bg: 'Bulgaria',
  bh: 'Bahrain',
  bi: 'Burundi',
  bj: 'Benin',
  bm: 'Bermuda',
  bn: 'Brunei Darussalalm',
  bo: 'Bolivia',
  br: 'Brazil',
  bs: 'Bahamas',
  bt: 'Bhutan',
  bv: 'Bouvet Island',
  bw: 'Botswana',
  by: 'Belarus',
  bz: 'Belize',
  ca: 'Canada',
  cc: 'Cocos (Keeling) Islands',
  cd: 'Democratic Republic of the Congo',
  cf: 'Central African Republic',
  cg: 'Republic of the Congo',
  ch: 'Republic of Switzerland',
  ci: 'Cote dlvoire',
  ck: 'Cook Islands',
  cl: 'Chile',
  cm: 'Cameroon',
  cn: 'China, mainland',
  cr: 'Costa Rica',
  cu: 'Cuba',
  cv: 'Cape Verde',
  cx: 'Christmas Island',
  cy: 'Cyprus',
  cz: 'Czech Republic',
  de: 'Federal Republic of Germany',
  dj: 'Djibouti',
  dk: 'Denmark',
  dm: 'Dominica',
  do: 'Dominican Republic',
  dz: 'Algeria',
  ec: 'Ecuador',
  ee: 'Estonia',
  eg: 'Egypt',
  er: 'Eritrea',
  es: 'Spain',
  et: 'Ethiopia',
  eu: 'European Union',
  fi: 'Finland',
  fj: 'Fiji',
  fk: 'Falkland Islands',
  fm: 'Federated States of Micronesia or radio related websites in general',
  fo: 'Faroe Islands',
  fr: 'France',
  ga: 'Gabon',
  gb: 'United Kingdom, not used as widely as .uk',
  gd: 'Grenada',
  ge: 'Georgia',
  gf: 'French Guiana',
  gg: 'Guernsey',
  gh: 'Ghana',
  gi: 'Gibraltar',
  gl: 'Greenland',
  gm: 'Gambia',
  gn: 'Guinea',
  gp: 'Guadeloupe',
  gq: 'Equatorial Guinea',
  gr: 'Greece',
  gs: 'South Sandwich Islands and South Georgia',
  gt: 'Guatemala',
  gw: 'Guinea-Bissau',
  gy: 'Gyana',
  hk: 'Hong Kong',
  hm: 'Heard Island and McDonald Islands',
  hn: 'Honduras',
  hr: 'Croatia',
  ht: 'Haiti',
  hu: 'Hungary',
  id: 'Indonesia',
  ie: 'Ireland',
  il: 'Israel',
  im: 'Isle of Man',
  in: 'India',
  iq: 'Iraq',
  ir: 'Iran',
  is: 'Iceland',
  it: 'Italy',
  je: 'Jersey',
  jm: 'Jamaica',
  jo: 'Jordan',
  jp: 'Japan',
  ke: 'Kenya',
  kg: 'Kyrgyzstan',
  kh: 'Cambodia',
  ki: 'Kiribati',
  km: 'Comoros',
  kn: 'Saint Kitts and News',
  kp: 'North Korea',
  kr: 'South Korea',
  kw: 'Kuwait',
  ky: 'Cayman Islands',
  kz: 'Kazakhstan',
  la: 'Laos',
  lb: 'Lebanon',
  lc: 'Saint Lucia',
  li: 'Liechtenstein',
  lk: 'Sri Lanka',
  lr: 'Liberia',
  ls: 'Lesotho',
  lt: 'Lithuania',
  lu: 'Luxembourg',
  lv: 'Latvia or popularly known as "Las Vegas"',
  ly: 'Libya',
  ma: 'Morocco',
  mc: 'Monaco',
  md: 'Moldova',
  me: 'Montenegro and also a domain name for personal websites',
  mg: 'Madagascar',
  mh: 'Marshall Islands',
  mk: 'Republic of Macedonia',
  ml: 'Mali',
  mm: 'Myanmar',
  mn: 'Mongolia',
  mo: 'Macau',
  mp: 'Northern Mariana Islands',
  mq: 'Martinique',
  mr: 'Mauritania',
  mt: 'Malta',
  mu: 'Mauritius',
  mv: 'Maldives',
  mw: 'Malawi',
  mx: 'Mexico',
  my: 'Malaysia',
  mz: 'Mozambique',
  na: 'Namibia',
  nc: 'New Caledonia',
  ne: 'Niger',
  nf: 'Norfolk Island',
  ng: 'Nigeria',
  ni: 'Nicaragua',
  nl: 'Netherlands',
  no: 'Norway',
  np: 'Nepal',
  nr: 'Nauru',
  nu: 'Niue',
  nz: 'New Zealand',
  om: 'Oman',
  pa: 'Panama',
  pe: 'Peru',
  pf: 'French Polynesia',
  pg: 'Papua New Guinea',
  ph: 'Philippines',
  pk: 'Pakistan',
  pl: 'Poland',
  pm: 'Saint-Pierre and Miquelon',
  pn: 'Pitcairn Islands',
  pr: 'Puerto Rico',
  pt: 'Portugal',
  pw: 'Palau',
  py: 'Paraguay',
  qa: 'Qatar',
  re: 'Reunion',
  ro: 'Romania',
  rs: 'Serbia',
  ru: 'Russia',
  rw: 'Rwanda',
  sa: 'Saudia Arabia',
  sb: 'Solomon Islands',
  sc: 'Seychelles',
  sd: 'Sudan',
  se: 'Sweden',
  sg: 'Singapore',
  sh: 'Saint Helena',
  si: 'Slovenia',
  sj: 'Svalbard and Jan Mayen Islands',
  sk: 'Slovakia',
  sl: 'Sierra Leone',
  sm: 'San Marino',
  sn: 'Senegal',
  so: 'Somalia',
  sr: 'Suriname',
  st: 'Sao Tome and Principe',
  su: 'The former Soviet Union',
  sv: 'El Salvador',
  sy: 'Syria',
  sz: 'Swaziland',
  tc: 'Turks and Caicos Islands',
  td: 'Chad',
  tf: 'French Southern and Antarctic Lands',
  tg: 'Togo',
  th: 'Thailand',
  tj: 'Tajikistan',
  tk: 'Tokelau',
  tl: 'East Timor',
  tm: 'Turkmenistan',
  tn: 'Tunisia',
  to: 'Tonga',
  tp: 'East Timor',
  tr: 'Turkey',
  tt: 'Trinidad and Tobago',
  tw: 'Taiwan',
  tz: 'Tanzania',
  ua: 'Ukraine',
  ug: 'Uganda',
  uk: 'United Kingdom',
  uy: 'Uruguay',
  uz: 'Uzbekistan',
  va: 'Vatican City State',
  vc: 'Saint Vincent and the Grenadines',
  ve: 'Venezuela',
  vg: 'British Virgin Islands',
  vu: 'Vanuatu',
  wf: 'Wallis and Futuna',
  ws: 'Western Samoa and also promoted for general use as “website”',
  ye: 'Yemen',
  yt: 'Mayotte',
  yu: 'Yugoslavia',
  za: 'South Africa',
  zm: 'Zambia',
  zw: 'Zimbabwe',
}
