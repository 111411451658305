/** @format */

export const THEME = {
  color: {
    primary: '#7EFEB4',
    secondary: '#7359e1',
    tertiary: '#FFD465',
  },
  colors: {
    neutral: {
      0: '#F2F3F5',
      10: '#F5F2ED',
      100: '#EAEDF3',
      200: '#D7DAE0',
      300: '#BFC2C7',
      400: '#A6A9AD',
      500: '#8E9194',
      600: '#76787A',
      700: '#5E5F61',
      800: '#454647',
      900: '#313233',
    },
    purple: {
      100: '#C8D2F9',
      200: '#D1D7F0',
      300: '#C5CDE9',
      400: '#ADB5D0',
      500: '#9FA7C0',
      600: '#9099B4',
      700: '#838CA8',
      800: '#6E7793',
      900: '#51576E',
    },
    green: {
      100: '#C1E0DF',
      200: '#C3D4D3',
      300: '#A1B4B3',
      400: '#93A1A0',
      500: '#83908F',
      600: '#728684',
      700: '#5F7371',
      800: '#4E5F5E',
      900: '#3F4D4C',
    },
    yellow: {
      100: '#EDE8CC',
      200: '#E0DBC1',
      300: '#C4BEA2',
      400: '#AEA992',
      500: '#97937E',
      600: '#8A8570',
      700: '#726E5B',
      800: '#5E5A4A',
      900: '#474539',
    },
    red: {
      0: '#FFF6F6',
      100: '#EDD8CC',
      200: '#E0CCC1',
      300: '#C7B5AB',
      400: '#AD9E95',
      500: '#948B87',
      600: '#887B76',
      700: '#796E69',
      800: '#625955',
      900: '#47413E',
    },
  },
  space: {
    1: 4,
    2: 8,
    3: 16,
    4: 24,
    5: 32,
    6: 40,
    7: 48,
    8: 56,
    9: 64,
    10: 72,
  },
  size: {
    1: 12,
    2: 16,
    3: 20,
    4: 24,
    5: 28,
    6: 32,
    7: 36,
    8: 40,
    9: 44,
    10: 48,
  },
  font: {
    display: 'lietome',
    body: 'alice',
  },
}
